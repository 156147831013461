import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import EmailIcon from "../../assets/images/Icon_Email.png";
// import Address from "../../assets/images/Icon_Address.png";
// import PhoneIcon from "../../assets/images/Icon_Phone.png";
import CloseIcon from "../../assets/images/closeIcon.png";
import { useTranslation } from 'react-i18next';
import "../../i18n"

const MobileMenu = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  useEffect(() => {
    const offCanvasNav = document.querySelector("#offcanvas-navigation");
    const offCanvasNavSubMenu = offCanvasNav.querySelectorAll(`.subMenu`);
    const anchorLinks = offCanvasNav.querySelectorAll("a");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        `<span class="menuExpand"><i></i></span>`
      );
    }

    const menuExpand = offCanvasNav.querySelectorAll(".menuExpand");
    const numMenuExpand = menuExpand.length;

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", (e) => {
        sideMenuExpand(e);
      });
    }

    for (let i = 0; i < anchorLinks.length; i++) {
      anchorLinks[i].addEventListener("click", () => {
        closeMobileMenu();
      });
    }
  });

  const sideMenuExpand = (e) => {
    e.currentTarget.parentElement.classList.toggle("active");
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.remove("active");
  };

  const serviceData = [
    {
      serviceTitle: t('GST'),
      slug: "gst-advisory-services",
    },
    {
      serviceTitle: t('Incometax'),
      slug: "income-tax-advisory-services",
    },
    {
      serviceTitle: t('Customsduty'),
      slug: "customs-duty",
    },
    {
      serviceTitle: t('ExportImportadvisory'),
      slug: "export-import-advisory-services",
    },

    {
      serviceTitle: t('GreenFieldProjectSetUp'),
      slug: "green-field-project-set-up",
    },
    {
      serviceTitle: t('LandRevenueAcquisition'),
      slug: "land-revenue-acquisition",
    },
  ];



  return (
    <div className="offcanvasMobileMenu" id="offcanvas-mobile-menu">
      <button
        className="offcanvasMenuClose"
        id="mobile-menu-close-trigger"
        onClick={() => closeMobileMenu()}
      >
        {/* <i className="icon-glyph-146"></i> */}
        {/* <i class="fa fa-close"></i> */}
        <img src={CloseIcon} className="img-fluid" alt="img" />
      </button>

      <div className="offcanvasWrapper">
        <div className="offcanvasInnerContent">
          <nav className="offcanvasNavigation" id="offcanvas-navigation">
            <ul>
              <li class="custom-dropdown">
                <select onChange={changeLanguage} defaultValue={i18n.language}>
                  <option value="en">English</option>
                  <option value="gu">ગુજરાતી</option>
                  <option value="hi">हिन्दी</option>
                </select>
              </li>

              <li>
                <Link to="/">{t("Home")}</Link>
              </li>
              <li>
                <Link to="/about">{t('Aboutus')}</Link>
              </li>
              <li className="menuItemHasChildren">
                {t('Services')}
                <ul className="subMenu">
                  {serviceData.map((data) => {
                    return (
                      <>
                        <li>
                          {" "}
                          <Link to={`/services/${data.slug}`}>
                            {data.serviceTitle}
                          </Link>{" "}
                        </li>
                      </>
                    );
                  })}
                </ul>
              </li>

              <li>
                <Link to="/contact">{t('Contactus')}</Link>
              </li>
              <li>
                {/* <Link to="/contact">{t('Contactus')}</Link> */}
                <select className="Language-dropdown-option"
                  onChange={changeLanguage}
                  defaultValue={i18n.language}
                >
                  <option value="en" className="drop-down-text-color">English</option>
                  <option value="gu" className="drop-down-text-color">ગુજરાતી</option>
                  <option value="hi" className="drop-down-text-color">हिन्दी</option>
                </select>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
