import React from "react";
import { Link } from "react-router-dom";
// import Logo from "../assets/images/South-Asia-Logo.png";
import Logo from "../assets/images/AALogo.jpg";
import LocationLogo from "../assets/svg/LocationLogo";
import EmailLogo from "../assets/svg/EmailLogo";
import PhoneLogo from "../assets/svg/PhoneLogo";
import InstagramLogo from "../assets/svg/InstagramLogo";
import FaceBookLogo from "../assets/svg/FaceBookLogo";
import TwiiterLogo from "../assets/svg/TwiiterLogo";
import LinkedinLogo from "../assets/svg/LinkedinLogo";
// import IFDCLogo from "../assets/images/IWCF-LOGO.png";
// import IADCLogo from "../assets/images/IADC-LOGO.png";
import { useTranslation } from 'react-i18next';

const Footer = () => {

  const { t } = useTranslation();

  const serviceData = [
    {
      serviceTitle: t('GST'),
      slug: "gst-advisory-services",
      id: 1,
    },

    {
      serviceTitle: t('Incometax'),
      slug: "income-tax-advisory-services",
      id: 2,
    },
    {
      serviceTitle: t('Customsduty'),
      slug: "customs-duty",
      id: 3,
    },
    {
      serviceTitle: t('ExportImportadvisory'),
      slug: "export-import-advisory-services",
      id: 4,
    },
    {
      serviceTitle: t('GreenFieldProjectSetUp'),
      slug: "green-field-project-set-up",
      id: 5,
    },
    {
      serviceTitle: t('LandRevenueAcquisition'),
      slug: "land-revenue-acquisition",
      id: 6,
    },
  ];

  return (
    <>
      <footer className="footer-section row m-0 text-capitalize ">
        <div className="col-12  col-lg-3 col-md-3 col-sm-12 px-3 px-lg-5">
          <div className="widget">
            <div className="footer_logo d-flex align-items-center">
              <Link to="/" className="text-white">
                <img
                  className="img-responsive rounded-circle abhisa-logo"
                  src={Logo}
                  alt=""
                />
                <span className="AbhisaName2"> {t('AbhisaAdvisory')} </span>
              </Link>
            </div>

            <div className="footer_socil mt-2">
              <ul className="list-icons link-list footer_soc">
                <li>
                  <a
                    href="https://www.instagram.com/AbhisaAdvisory"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramLogo />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/AbhisaAdvisory/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaceBookLogo />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/AbhisaAdvisory"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwiiterLogo />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.linkedin.com/company/AbhisaAdvisory/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedinLogo />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-3 col-md-4 mt-2">
          <ul className="list-unstyled">
            <h1 className="footer-heading">{t('ContactInfo')}</h1>
            <li className="d-flex gap-1 mb-2">
              <div className="mt-2">
                <a
                  href="https://www.google.com/maps?q=E-412 Titanium City Center Office, Anandnagar Road, Ahmedabad, Gujarat 380015"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LocationLogo />
                </a>
              </div>
              <div className="mb-2 mt-2 mx-2">
                <a
                  href={`https://www.google.com/maps?q=E-412 Titanium City Center Office, Anandnagar Road, Ahmedabad, Gujarat 380015`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white"
                >
                  {t('Address')}
                </a>
              </div>
            </li>
            <li className="d-flex gap-1 mb-2">
              <div>
                <PhoneLogo />
              </div>
              <div className="mx-2">
                <a href="tel:+918735838595" className="text-white">
                  (+91) 8735838595{" "}
                </a>
              </div>
            </li>
            <li className="d-flex gap-1 mb-2">
              <div>
                <EmailLogo />
              </div>
              <div className="mb-2 mx-2 text-lowercase">
                <a href="mailto:advisory@abhisa.in" className="text-white">
                  advisory@abhisa.in
                </a>
              </div>
            </li>
          </ul>
        </div>

        <div className="col-12 col-lg-2  col-md-2 col-sm-12 ">
          <h1 className="footer-heading">{t('Company')}</h1>
          <ul>
            <li>
              <Link to="/" className="text-white">
                {t('Home')}
              </Link>
            </li>
            <li>
              <Link to="/about" className="text-white">
                {t('Aboutus')}
              </Link>
            </li>

            <li>
              <Link to="/contact" className="text-white">
                {t('Contactus')}
              </Link>
            </li>
            <li>
              <a href="https://abhisa.in/sitemap.xml" className="text-white">
                {t('Aboutus')}
              </a>
            </li>
            <li>
              <Link to="/policy-details" className="text-white">
                {t('PrivacyPolicy')}
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-12 col-lg-4 col-md-3  ">
          <h1 className="footer-heading ">{t('Services')}</h1>
          <ul>
            <div className="">
              <div className="">
                {serviceData.map((data) => {
                  return (
                    <>
                      <li>
                        <Link
                          to={`/services/${data.slug}`}
                          className="text-white"
                        >
                          {data.serviceTitle}
                        </Link>
                      </li>
                    </>
                  );
                })}
              </div>

            </div>
          </ul>
        </div>

        <div className="subFooterSouth py-4">
          <p className="text-center">
            © {new Date().getFullYear()} {t('RightReserved')}
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
