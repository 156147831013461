import MainTitle from "components/Common/MainTitle";
import React from "react";
import ON_GC_IMG from "../../assets/images/ONGC.png";
import { useTranslation } from 'react-i18next';

function SectionOngoingProject() {
  const { t } = useTranslation();
  const OnGoingProjectData = [
    t('SectionOngoingProject1'),
    t('SectionOngoingProject2'),
    t('SectionOngoingProject3'),
  ];
  const OnGoingProjectContent = [
    t('SectionOngoingProject4'),
    t('SectionOngoingProject5'),
    t('SectionOngoingProject6'),
    t('SectionOngoingProject7'),
  ];
  return (
    <>
      <div className="container pt-4 ">
      
          <MainTitle text={t('SectionOngoingMainTitle1')} className={'text-center'} position={'center'} width={'171px'}  />

      
        <div className="row">
          <div className="col-md-3">
            <img src={ON_GC_IMG} alt="img" className="img-fluid" />
          </div>
          <div className="col-md-8">
            {OnGoingProjectData?.map((data, index) => (
              <div className="d-flex" key={index}>
                <div className=" NewsIcon">
              <i className="fa fa-circle primary-color" aria-hidden="true" ></i>

                </div>
                <li className="projectList ls-none ">{data}</li>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          {OnGoingProjectContent?.map((content, index) => (
            <div className="d-flex" key={index}>
              <div className=" NewsIcon"></div>
              <li className="projectList ">{content}</li>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SectionOngoingProject;
