import React from "react";

import { Navbar, Nav } from "react-bootstrap";
import MobileMenu from "../components/mobile-menu/MobileMenu";
import MobileBtn from "../components/mobile-menu/MobileBtn";
import { useMediaQuery } from "react-responsive";
import Logo from "../assets/images/AALogo.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../i18n' // Make sure to import your i18n configuration


const Menu = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  console.log(i18n.languages, 'language');



  const isMobile = useMediaQuery({ maxWidth: 768 });

  const serviceData = [
    {
      serviceTitle: t('GST'),
      slug: "gst-advisory-services",
    },
    {
      serviceTitle: t('Incometax'),
      slug: "income-tax-advisory-services",
    },
    {
      serviceTitle: t('Customsduty'),
      slug: "customs-duty",
    },
    {
      serviceTitle: t('ExportImportadvisory'),
      slug: "export-import-advisory-services",
    },

    {
      serviceTitle: t('GreenFieldProjectSetUp'),
      slug: "green-field-project-set-up",
    },
    {
      serviceTitle: t('LandRevenueAcquisition'),
      slug: "land-revenue-acquisition",
    },
  ];
  return (
    <>
      {isMobile ? (
        <>
          <div className="menu_area">
            <div className="navigation">
              <div className="container">
                <div className="logo">
                  {/* <Link to="/"> */}
                  <img
                    src={Logo}
                    alt="logo"
                    className="img-fluid Mobile-logo rounded-circle"
                  />
                  {/* </Link> */}
                </div>
                <Link to="/">
                  <p
                    className=" Mobile-Heading "
                  >

                    Abhisa Advisory
                  </p>
                </Link>
                <MobileBtn />
                <MobileMenu />
              </div>
              {/* <div className="Language-dropdown">
                <select className="Language-dropdown-option"
                  onChange={changeLanguage} // Handle change event
                  defaultValue={i18n.language} // Set default value based on current languag
                >
                  <option value="en" className="drop-down-text-color" >English</option>
                  <option value="gu" className="drop-down-text-color" >ગુજરાતી</option>
                  <option value="hi" className="drop-down-text-color" >हिन्दी</option>
                </select>
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="header">
            <div className="container ">
              <Navbar expand="lg" sticky="top">
                <div className="d-flex justify-content-between w-100">
                  <Link className="navbar-brand" to="/" contenteditable="false">
                    <img
                      src={Logo}
                      alt=""
                      className="img-fluid rounded-circle abhisa-logo"
                    />
                    <span className="mx-2 AbhisaName"> {t('AbhisaAdvisory')} </span>
                  </Link>
                  {/* Logo Section */}
                  <Nav
                    className="navbar navbar-expand-lg navbar-light d-flex justify-content-between nav-height"
                    id="navigation"
                  >

                    <div
                      className="collapse navbar-collapse"
                      id="navbarNavDropdown"
                    ></div>

                    <ul className="navbar-nav mr-5">
                      <li className=" ">
                        <Link
                          // to={process.env.PUBLIC_URL + "/"}
                          to="/"
                          className={` menu-text-south `}
                        >
                          {t('Home')}
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/about" className="menu-text-south">
                          {t('Aboutus')}{" "}
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="" className="menu-text-south ">
                          {t('Services')}{" "}
                        </Link>
                        <ul>
                          {serviceData.map((data) => {
                            return (
                              <>
                                <li>
                                  <Link to={`/services/${data.slug}`}>
                                    {data.serviceTitle}
                                  </Link>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </li>
                      <li className="active ">
                        <Link to="/contact" className="menu-text-south ">
                          {/* Contact us{" "} */}
                          {t('Contactus')}
                        </Link>
                      </li>
                    </ul>

                  </Nav>
                  <div className="drop-down">


                    <select className="Language-dropdown-option"
                      onChange={changeLanguage} // Handle change event
                      defaultValue={i18n.language} // Set default value based on current languag
                    >
                      <option value="en" className="drop-down-text-color">ENGLISH</option>
                      <option value="gu" className="drop-down-text-color">ગુજરાતી</option>
                      <option value="hi" className="drop-down-text-color" >हिन्दी</option>
                    </select>
                  </div>
                </div>
              </Navbar>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Menu;
