import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import { withTranslation  } from "react-i18next";

class ServiceSidebar extends Component{
	render(){
        const { t } = this.props; 

	const serviceListArray = [
		{
			serviceTitle:t('ServiceContentTitle2'),
			sidebarLink:"single-services",  
		},
		{
			serviceTitle:t('ServiceContentTitle1'),
			sidebarLink:"single-services",  
		},
		{
			serviceTitle:t('ServiceContentTitle3'),
			sidebarLink:"single-services",  
		},
		{
			serviceTitle:t('GalleryContentTitle6'),
			sidebarLink:"single-services",  
		},
		{
			serviceTitle:t('GalleryContentTitle2'),
			sidebarLink:"single-services",  
		},
		{
			serviceTitle:t('GalleryContentTitle4'),
			sidebarLink:"single-services",  
		},
	]

 

    const serviceListMap = serviceListArray.map((val, i) => {
        return(
			<li key={i}><Link to={`${val.sidebarLink}`}>{val.serviceTitle}</Link></li>
        )
    });

	return(
		<div className="single_service_right">
            <div className="single_service_cat">
                <ul>
                    {serviceListMap}
                </ul>
            </div>
            <div className="service_contact"> 
                <h4> {t('ContactUs')} </h4>
                <div className="serv_contact_wrp">
                    <div className="single-contact-info">
                        <i className="fa fa-phone"></i>
                        <p>+122 (345) 345 72</p>
                    </div>
                    <div className="single-contact-info">
                        <i className="fa fa-envelope"></i>
                        <p>info-revita@acb.com</p>
                    </div>
                    <div className="single-contact-info">
                        <i className="fa fa-globe"></i>
                        <p>{t('SidebarAddress1')} <br/>{t('SidebarAddress2')}</p>
                    </div> 
                </div>
            </div>
            <div className="download_brochur">
                <h4>{t('Downloads')} </h4>
                <p>{t('lorem')} </p>
                <ul className="files">
                    <li><Link to={process.env.PUBLIC_URL + "/single-services"}><span className="fa fa-file-pdf-o"></span> {t('DownloadAsPdf')}</Link>
                    </li>
                    <li><Link to={process.env.PUBLIC_URL + "/single-services"}><span className="fa fa-file-word-o"></span> {t('DownloadAsDoc')} </Link>
                    </li>
                </ul>
            </div>
        </div>
 	)

	}
}

 
export default withTranslation()(ServiceSidebar);