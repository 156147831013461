import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const SubscribeSection = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className='container text-center my-5'>
                <div className='subscribe-class'>{t('LikeToHerefromUs')}</div>
                <h1 className='subscribe-h1'>{t('SubscribeHeading')}</h1>
                <div>
                    <input type='email' className='form-group py-3 col-7' placeholder={t('EmailPlaceHolder')} />
                    <Link to='/' className="subscribe-btn">{t('SubscribeBtn')}</Link>

                </div>
            </div>
        </>
    );
};

export default SubscribeSection;
