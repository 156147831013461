import React from "react";
// import MainTitle from "../Common/MainTitle";
import AboutAsia from "../../assets/images/AboutAsia.jpg";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';

function SectionAboutConsultancy() {
  const { t } = useTranslation();
  return (
    <>
      <div className="about-firm">
        <div className="container">
          <div className="row">
            <div class="col-sm-12 col-md-7 col-lg-7 col-xl-8">
              <Fade direction="left" triggerOnce>
                <div className="about-para">
                  <h2>{t('AboutAbhisaAdvisory')}</h2>

                  <p className="text-justify">
                    {/* Welcome to Abhisa Advisory LLP, your trusted partner for all
                    your income tax needs. We specialize in providing expert
                    guidance and solutions to individuals and businesses to
                    navigate the complexities of income tax laws and regulations. */}
                    {t('AboutAbhisaAdvisoryParagraph')}
                  </p>
                </div>
              </Fade>
            </div>
            <div class="col-sm-12 col-md-5 col-lg-5 col-xl-4 mx-auto">
              <Fade direction="up" triggerOnce>
                <div className="about-img">
                  <img
                    src={AboutAsia}
                    className="img-fluid lazy"
                    alt="about photo"
                    decoding="async"
                  />
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container pt-4 mb-4"> */}
      {/* <div className="d-flex justify-content-center"> */}
      {/* <MainTitle text="About Abhisa " className={'text-center'} position={'center'} width={'171px'}/> */}
      {/* </div> */}
      {/* <div className="row pt-2">
          <div className="col-md-7">
            <p className="text-justify vision-text">
              <b>Abhisa</b>  is an ISO 9001 certified company with
              headquarter in Ahmedabad, Gujarat, providing oilfield services.
              SAC was established in 2014 by its sole proprietor Mr. D S Rajput
              to supply quality manpower to Oil & Gas companies worldwide. SAC
              began providing Operation & Maintenance Services for various
              Oilfield activities including Drilling, Workover etc. in onshore
              as well as offshore and today, SAC is considered as one of the
              leading company providing Oil and Gas Services. SAC has supplied
              quality manpower to Manage Jack-up Drilling Rigs in the Persian
              Gulf and successfully executed the contracts. SAC has vast
              experience in managing onshore and offshore drilling rigs up to
              3000 HP capacity, Amphion and Cyberbase control, and as latest as
              7th generation rigs of reputed make like NOV, Drillmec,
              LeTourneau, Romania etc. With objective of monetizing discovered
              small Oil and Gas Field to boost domestic oil and gas production,
              Govt. of India has awarded South Patan Field to Abhisa
              Consultancy through DSF-2016 Bidding Round, and SAC entered into
              the upstream Oil and Gas Exploration and Production Sector. South
              Patan Oilfeld is in Cambay Basin, Gujarat. SAC seeks to enhance
              its worldwide position in its market niche through continued focus
              on quality service. SAC seeks to create a Global Energy leader
              that responsibly caters to energy demand.
            </p>
          </div>
          <div className="col-md-5">
            <img src={AboutAsia} className="img-fluid" alt="About" />
          </div>
        </div>
      </div> */}
    </>
  );
}

export default SectionAboutConsultancy;
