import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";
import { useTranslation } from "react-i18next";

const Checkout = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <MetaTags>
        <title>{t('AbhisaAdvisory')} | {t('About')}</title>
        <meta name="description" content="" />
      </MetaTags>
      <LayoutOne>
        <div className="checkout-page">
          {/*====================  breadcrumb area ====================*/}

          <Breadcrumb title={t('CartHeader')} />

          {/*====================  End of breadcrumb area  ====================*/}

          {/*====================  Start of Checkout  Section    ====================*/}

          <section className="checkout_section">
            <div className="container">
              <div className="row">
                <div id="content" className="col-lg-12 col-sm-12">
                  <div name="contactform" method="post" className="shopform">
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <div className="custom-title">
                          <h3>{t('ChekoutBillingdetails')}</h3>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-lg-6 col-sm-12">
                            <label>{t('ChekoutFirstName')} *</label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                            />
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <label>{t('ChekoutLastName')} *</label>
                            <input
                              type="text"
                              name="name"
                              id="name1"
                              className="form-control"
                            />
                          </div>
                          <div className="col-lg-12 col-sm-12">
                            <label>{t('ChekoutCompanyName')}</label>
                            <input
                              type="text"
                              name="name"
                              id="name2"
                              className="form-control"
                            />
                          </div>
                          <div className="col-lg-12 col-sm-12">
                            <label>{t('ChekoutAddress')} *</label>
                            <input
                              type="text"
                              name="name"
                              id="name3"
                              className="form-control"
                            />
                            <label>{t('ChekoutAddressLine2')}</label>
                            <input
                              type="text"
                              name="name"
                              id="name4"
                              className="form-control"
                            />
                          </div>
                          <div className="col-lg-12 col-sm-12">
                            <label>{t('ChekoutTownCity')}*</label>
                            <input
                              type="text"
                              name="name"
                              id="name5"
                              className="form-control"
                            />
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <label>{t('ChekoutCounty')} *</label>
                            <input
                              type="text"
                              name="name"
                              id="name6"
                              className="form-control"
                            />
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <label>{t('ChekoutZipCode')} *</label>
                            <input
                              type="text"
                              name="name"
                              id="name7"
                              className="form-control"
                            />
                          </div>
                          <div className="col-lg-12 col-sm-12">
                            <label>{t('ChekoutEmail')} *</label>
                            <input
                              type="text"
                              name="name"
                              id="name8"
                              className="form-control"
                            />
                            <label>{t('ChekoutPhoneNumber')} *</label>
                            <input
                              type="text"
                              name="name"
                              id="name9"
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/*  end row */}
                      </div>
                      {/*  end col */}

                      <div className="col-lg-6 col-sm-12">
                        <div className="custom-title">
                          <h3>{t('ChekoutShippingaddress')}</h3>
                        </div>
                        <br />
                        <label>{t('ChekoutOtherNotes')}</label>
                        <textarea
                          rows="5"
                          className="form-control"
                          placeholder="Add extra notes..."
                        ></textarea>

                        <div className="coupon-area">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="coupon_show">
                                  {/* ACCORDION START */}
                                  <h3>
                                    {t('ChekoutReturningcustomer')}{" "}
                                    <span id="showlogin">
                                      {t('Chekoutlogin')}
                                    </span>
                                  </h3>
                                  <div
                                    id="checkout-login"
                                    className="coupon-content"
                                    style={{ display: "none" }}
                                  >
                                    <div className="coupon_info">
                                      <form action="#">
                                        <p className="input_name">
                                          <label>
                                            {t('ChekoutUsernameoremail')}{" "}
                                            <span className="required">*</span>
                                          </label>
                                          <input type="text" />
                                        </p>
                                        <p className="input_pass">
                                          <label>
                                            {t('ChekoutPassword')}{" "}
                                            <span className="required">*</span>
                                          </label>
                                          <input type="text" />
                                        </p>
                                        <p className="input_login">
                                          <input
                                            type="submit"
                                            className="button"
                                            value="Login"
                                          />
                                          <label>
                                            <input type="checkbox" /> {t('ChekoutRememberme')}
                                          </label>
                                        </p>
                                        <p className="lost_pass">
                                          <a href="/">{t('ChekoutLostyourpassword')}</a>
                                        </p>
                                      </form>
                                    </div>
                                  </div>
                                  {/* ACCORDION END */}

                                  {/* ACCORDION START */}
                                  <h3>
                                    {t('ChekoutHavecoupon')}{" "}
                                    <span id="showcoupon">
                                      {t('ChekoutEnterCode')}
                                    </span>
                                  </h3>
                                  <div
                                    id="checkout_coupon"
                                    className="coupon-checkout-content"
                                    style={{ display: "none" }}
                                  >
                                    <div className="coupon-info">
                                      <form action="#">
                                        <p className="checkout-coupon">
                                          <input
                                            type="text"
                                            placeholder="Coupon code"
                                          />
                                          <input
                                            type="submit"
                                            value="Apply Coupon"
                                          />
                                        </p>
                                      </form>
                                    </div>
                                  </div>
                                  {/* ACCORDION END */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end COL */}
                    </div>
                    {/* end row */}
                  </div>

                  <div className="payment_mth">
                    <div className="custom-title">
                      <h3>{t('ChekoutPaymentmethod')} </h3>
                    </div>
                    <br />
                    <form id="payment" className="clearfix">
                      <label className="checkbox payment-method inline">
                        <input
                          type="checkbox"
                          id="customCheck1"
                          value="option1"
                          className=""
                        />{" "}
                        {t('ChekoutDirectBankTransfer')}
                      </label>
                      <label className="checkbox payment-method inline">
                        <input
                          type="checkbox"
                          id="customCheck2"
                          value="option2"
                        />{" "}
                        {t('ChekoutChequePayment')}
                      </label>
                      <label className="checkbox payment-method inline">
                        <input
                          type="checkbox"
                          id="customCheck4"
                          value="option2"
                        />{" "}
                        {t('ChekoutPayPalPayment')}
                      </label>
                      <label className="checkbox payment-method inline">
                        <input
                          type="checkbox"
                          id="customCheck3"
                          value="option3"
                        />{" "}
                       {t('ChekoutGoogleCheckout')}
                      </label>
                      <a href="#/" className="more-link">
                        {t('ChekoutPlaceAnOrder')}
                      </a>
                    </form>
                  </div>
                </div>
                {/* end content */}
              </div>
              {/* end row */}
            </div>
            {/* end container*/}
          </section>

          {/*====================  End of Checkout  Section    ====================*/}
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default Checkout;
