import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import directIndirectTax from "../../assets/images/direct-indirect-tax.jpg";
import gstImg from "../../assets/images/AbhisaGSTFinal.png";
import incomeTaxImg from "../../assets/images/AbhisaIncomeTaxAdvisoryServicesOfIndia.png";
import customeDutyImg from "../../assets/images/Customs-duty.png";
import AbhisaImportAndExportConsultancy from "../../assets/images/AbhisaImportAndExportConsultancy.png";
import greenLayout from "../../assets/images/AbhisaLandAquisitionndGreenFieldManufacturingProjectSetUp.png";
import landRevenue from "../../assets/images/LandRevenueNewImages.jpg";
// import MainTitle from "components/Common/MainTitle";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const MultiSlider = () => {
  const { t } = useTranslation();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const serviceData = [
    {
      imgUrl: gstImg,
      serviceTitle:t('GST'),
      serviceUrl: "gst-advisory-services",
    },
    {
      imgUrl: incomeTaxImg,
      serviceTitle: t('Incometax'),
      serviceUrl: "income-tax-advisory-services",
    },
    {
      imgUrl: customeDutyImg,
      serviceTitle: t('Customsduty'),
      serviceUrl: "customs-duty",
    },
    {
      imgUrl: AbhisaImportAndExportConsultancy,
      serviceTitle: t('ExportImportadvisory'),
      serviceUrl: "export-import-advisory-services",
    },
    {
      imgUrl: greenLayout,
      serviceTitle: t('GreenFieldProjectSetUp'),
      serviceUrl: "green-field-project-set-up",
    },
    {
      imgUrl: landRevenue,
      serviceTitle: t('LandRevenueAcquisition'),
      serviceUrl: "land-revenue-acquisition",
    },
  ];

  return (
    <>
      <div class="our-vision ">
        <div class="container">
          <div class="text-center mb-5">
            <div class="vision-h2">
              <Fade direction="up" triggerOnce>
                <h2 className="align-top Our-Vision-Name">{t('OurVision')}</h2>
              </Fade>
            </div>
            <div class="">
              <Fade direction="up" triggerOnce>
                <p class=" text-justify">
                  
                  {"'"}{t('OurvisionHeadLine')}{"'"}
                </p>
              </Fade>
            </div>
          </div>
        </div>

        <div id="home-service">
          <div className="container">
            <div className="service-title">
              <Fade direction="left" triggerOnce>
                <h2 className="Service-title-name">{t('ServiceWeOffer')}</h2>
              </Fade>
            </div>
          </div>
          <Carousel
            responsive={responsive}
            infinite={true}
            swipeable={true}
            keyBoardControl={true}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            containerClass="carousel-container"
            itemClass="carousel-item-padding-0"
            autoPlay={true} // Enable auto play
            autoPlaySpeed={2000} // Auto play interval in milliseconds (2 seconds)
            removeArrowOnDeviceType={["tablet", "mobile"]} // Hide arrows on smaller devices
          >
            {serviceData?.map((data) => {
              return (
                <>
                  <div>
                    <Link to={`services/${data.serviceUrl}`}>
                      <img
                        className="service-img"
                        src={data?.imgUrl}
                        alt="Direct and Indirect Tax"
                      />
                    </Link>

                    <h1 className="service-text">{data?.serviceTitle}</h1>
                  </div>
                </>
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default MultiSlider;
