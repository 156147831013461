import LocationLogoContactUs from 'assets/svg/LocationLogoContactUs'
import React from 'react'
import { useTranslation } from 'react-i18next';

function SectionAddress() {
const { t } = useTranslation();
    return (
        <>
            <div className='container' >
                <div className='row py-4'>
                    <div className='contact-address'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <LocationLogoContactUs />
                            <h2 className='primary-color mx-2'>
                                {t('RegisteredOffice')}
                            </h2>

                        </div>
                        <div className='d-flex justify-content-center mt-2 '>

                            <p className=' contact-us-address fs20px lnh-32 text-center'>
                               {t('Address')}
                            </p>
                        </div>
                        <div>
                            <p className='primary-color text-center fw600 fs16px'>
                                +91 8735838595    ||    advisory@abhisa.in
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SectionAddress
