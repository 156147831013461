import React from 'react'

function ServiceButton({ title }) {
    return (
        <>
            <div className=' d-flex align-items-center  capable-btn'>
                {title}
            </div>
        </>
    )
}

export default ServiceButton
