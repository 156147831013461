import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next';


function SectionOurVision() {
  const { t } = useTranslation();
    return (
        <>
        <div className='container'>
            <div className='row py-5'>
              <div className='col-md-12 text-center vision-about'>
                <Fade direction="left" triggerOnce>
                  <h3>{t('OurVision')}</h3>
                </Fade>
                 <Fade direction="up" triggerOnce>
                    <p className='text-justify vision-text'>
                    {t('OurvisionHeadLine')}
                    </p>
                 </Fade>
                 
              </div>
              <div className='col-md-12 text-center mt-5 vision-about'>
              <Fade direction="left" triggerOnce>
                <h3> {t('OurMission')} </h3>
                </Fade>
                <Fade direction="up" triggerOnce>
                    <p className='text-justify vision-text'>
                   {t('OurMissionParagraph')}
                    </p>
                </Fade>
              </div>
             

            </div>
        </div>
        </>
    )
}

export default SectionOurVision
