import React, { Component, Fragment } from "react";
import ServiceSidebar from "../components/ServiceSidebar";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";
import { withTranslation  } from "react-i18next";

class SingleService extends Component {
  render() {
    const { t } = this.props; 
    const serviceTopArray = [
      {
        image: "blog5.jpg",
        title: t("SingleBlog22"),
        desPartOne:
          t('SingleBlog8'),
        desPartTwo:
        t('SingleBlog8'),
      },
    ];

    const serviceBottomArray = [
      {
        icon: "process_3.png",
        title: t('SingleBlog20'),
        des: t('SingleBlog23'),
      },
      {
        icon: "process_4.png",
        title: t('SingleBlog21'),
        des: t('SingleBlog23'),
      },
    ];

    const serviceToptMap = serviceTopArray.map((val, i) => {
      return (
        <div className="single_service_left" key={i}>
          <img src={`assets/images/${val.image}`} alt="" />
          <h4>{val.title}</h4>
          <p>{val.desPartTwo}</p>
          <p>{val.desPartOne}</p>
        </div>
      );
    });

    const serviceBottomtMap = serviceBottomArray.map((val, i) => {
      return (
        <div className="col-md-6 col-sm-12" key={i}>
          <div className="sing_service_item">
            <div className="icon-serv">
              <img src={`assets/images/${val.icon}`} alt="" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.des}</p>
          </div>
        </div>
      );
    });

    return (
      <Fragment>
        <MetaTags>
          <title>{('BlogPageOneTitle2')}</title>
          <meta name="description" content="" />
        </MetaTags>
        <LayoutOne>
          <div className="single-services-page">
            {/*====================  breadcrumb area ====================*/}

            <Breadcrumb title="Single Services" />

            {/*====================  End of breadcrumb area  ====================*/}

            {/*====================  Single Services area ====================*/}
            <div className="single_service">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 col-sm-12">
                    {serviceToptMap}

                    <div className="row single_service_left_botom">
                      {serviceBottomtMap}
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-12">
                    <ServiceSidebar />
                  </div>
                </div>
              </div>
            </div>
            {/*====================  End Single Services area ====================*/}
          </div>
        </LayoutOne>
      </Fragment>
    );
  }
}

export default withTranslation()(SingleService);
