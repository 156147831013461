import React, { Component, Fragment } from "react";
// import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import SectionHeroBanner from "../components/Common/SectionHeroBanner";
// import DrillingService from "../assets/images/DrillingService.png";
// import GeneratorSets from "../assets/images/GeneratorState.png";
// import DuelFuel from "../assets/images/DueFuelEngine.png";
// import CrudOil from "../assets/images/CrudOil.png";
import SectionServiceTop from "components/ServiceInnerPage/SectionServiceTop";
import SectionServiceBottom from "components/ServiceInnerPage/SectionServiceBottom";
import { withTranslation  } from "react-i18next";


// import SAFETY from '../assets/images/IWCF.png'
class Services extends Component {
  render() {
    const { t } = this.props; 
    const CardDataSection1 = [
      {
        subTitle:
          t('ServicesContentpara1'),
        content1:
        t('ServicesContentpara2'),
        content2:
        t('ServicesContentpara3'),
      },
      {
        subTitle: t('ServicesContentpara4'),
        content:
        t('ServicesContentpara5'),
      },
    ];

    // const CardDataSection2 = [
    //   {
    //     CardImg: GeneratorSets,
    //     Title: t('ServicesContentpara6'),
    //     content:
    //     t('ServicesContentpara7'),
    //     slug: "generator-sets",
    //   },
    //   {
    //     CardImg: DuelFuel,
    //     Title: t('ServicesContentpara8'),
    //     content:
    //     t('ServicesContentpara9'),
    //     slug: "duel-fuel-engines-services",
    //   },
    //   {
    //     CardImg: DrillingService,
    //     Title: t('ServicesContentpara10'),
    //     content:
    //     t('ServicesContentpara11'),
    //     slug: "drilling-services",
    //   },
    //   {
    //     CardImg: CrudOil,
    //     Title: t('ServicesContentpara12'),
    //     content:
    //     t('ServicesContentpara13'),
    //     slug: "crude-oil-transportation-services",
    //   },
    // ];

    return (
      <Fragment>
        <MetaTags>
          <title> {t('AbhisaAdvisory')} | {t('Services')}</title>
          <meta name="description" content="" />

        </MetaTags>
        <LayoutOne>
          <SectionHeroBanner
            title={"Services"}
            className={"sectionServicesBg"}
          />
          <SectionServiceTop CardDataSection1={CardDataSection1} />
          <SectionServiceBottom />
        </LayoutOne>
      </Fragment>
    );
  }
}

export default withTranslation()(Services);
