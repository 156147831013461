import React from "react"; 
import PrivacyPolicyPage from "./PrivacyPolicyPage";
// import SectionBanner from "components/Common/SectionBanner";
import SectionHeroBanner from "components/Common/SectionHeroBanner";
import { useTranslation } from 'react-i18next';

const PrivacyPolicyContent = () => {
    const { t } = useTranslation();
    const PrivacyPolicyData = [
        {
            title: t('PrivacyPolicySection1Title'),
            content:[
                {
                    subContent: t('PrivacyPolicySection1Content')
                }
            ]
        },
        {
            title: t('PrivacyPolicySection2Title'),
            MainsubTitle:t('PrivacyPolicySection2MainSubTitle'),
            content:[
                {
                    subTitle: t('PrivacyPolicySection2Content1SubTitle'),
                    subContent: t('PrivacyPolicySection2Content1SubContent')
                },
                {
                    subTitle: t('PrivacyPolicySection2Content2SubTitle'),
                    subContent:t('PrivacyPolicySection2Content2SubContent')
                },
                {
                    subTitle: t('PrivacyPolicySection2Content3SubTitle'),
                    subContent: t('PrivacyPolicySection2Content3SubContent')
                }
            ]
        },
        {
            title: t('PrivacyPolicySection3Title'),
            MainsubTitle: t('PrivacyPolicySection3MainSubTitle'),
            content:[
                {
                    subContent: t('PrivacyPolicySection3Content1')
                },
                {
                    subContent:  t('PrivacyPolicySection3Content2')
                },
                {
                    subContent:  t('PrivacyPolicySection3Content3')
                },
                {
                    subContent:  t('PrivacyPolicySection3Content4')
                }
            ]
        },
        {
            title: t('PrivacyPolicySection4Title'),
            MainsubTitle: t('PrivacyPolicySection4MainSubTitle'),
            content:[
                {
                    subContent: t('PrivacyPolicySection4Content1')
                },
                {
                    subContent:t('PrivacyPolicySection4Content2')
                },
            ]
        },
        {
            title: t('PrivacyPolicySection5Title'),
            MainsubTitle: t('PrivacyPolicySection5MainSubTitle'),
            content:[
                {
                    subContent: t('PrivacyPolicySection5Content1')
                },
                {
                    subContent: t('PrivacyPolicySection5Content2')
                },
                {
                    subContent: t('PrivacyPolicySection5Content3')
                },
            ]
        },
        {
            title:t('PrivacyPolicySection6Title'),
            content:[
                {
                    subContent: t('PrivacyPolicySection6Content')
                },
            ]
        },
        {
            title: t('PrivacyPolicySection7Title'),
            content:[
                {
                    subContent: t('PrivacyPolicySection7Content')
                },
            ]
        },
        {
            title:t('PrivacyPolicySection8Title'),
            content:[
                {
                    subContent:t('PrivacyPolicySection8Content')
                },
            ]
        },
        {
            title: t('PrivacyPolicySection9Title'),
            content:[
                {
                    subContent: t('PrivacyPolicySection9Content')
                },
            ]
        },

    ]
    return (
        <>
            <SectionHeroBanner title={t('PrivacyPolicyTitle')} className={"sectionAboutBg"} />
            <PrivacyPolicyPage 
                PrivacyPolicyData={PrivacyPolicyData}
            />
        </>
    )
  };
  
export default PrivacyPolicyContent;