import React, { useState } from "react";
import MainTitle from "components/Common/MainTitle";
import { useTranslation } from 'react-i18next';

function SectionMap() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage("");

    try {
      const response = await fetch(
        "http://localhost:8000/public/sendMail.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setSubmitMessage(t('Messege1'));
      } else {
        setSubmitMessage(t('Messege2'));
      }
    } catch (error) {
      setSubmitMessage(t('Messege3'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="container my-5 ContactMain">
        <div className="row">
          <div className="col-md-5 p-0">
            <div className="d-flex justify-content-end">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14689.41633854654!2d72.50560415284409!3d23.010767816909564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e857b1e641f55%3A0x340942a1d8267e91!2sTitanium%20City%20Center%20-%20Corporate%20Offices!5e0!3m2!1sen!2sin!4v1720789941916!5m2!1sen!2sin"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="googleMap"
                style={{
                  width: "526px",
                  height: "628px",
                }}
              ></iframe>
            </div>
          </div>
          <div className="col-md-7">
            <MainTitle text={t('GetInTouchBtn')} className="my-4 mt-5" />
            <div>
              <form onSubmit={handleSubmit}>
                <div className="my-4">
                  <input
                    type="text"
                    name="firstName"
                    placeholder={t('ChekoutFirstName')}
                    className="CustomInputContactInput mr-2 p-2"
                    value={formData.firstName}
                    onChange={handleChange}
                  />

                  <input
                    type="text"
                    name="lastName"
                    placeholder={t('ChekoutLastName')}
                    className="CustomInputContactInput p-2"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </div>

                <div className="my-2">
                  <input
                    type="text"
                    name="phone"
                    placeholder={t('ChekoutPhoneNumber')}
                    className="CustomInputContactInput mr-2 p-2"
                    value={formData.phone}
                    onChange={handleChange}
                  />

                  <input
                    type="text"
                    name="email"
                    placeholder={t('ChekoutEmail')}
                    className="CustomInputContactInput p-2"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <input
                  type="text"
                  name="message"
                  placeholder={t('SingleBlog16')}
                  className="CustomInputContactTextArea p-2 my-2 mb-4"
                  value={formData.message}
                  onChange={handleChange}
                />
                <div className="">
                  <button
                    type="submit"
                    className="btn ContactPageBtn my-4"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : t('JobApplysubmitButton')}
                  </button>
                </div>
                {submitMessage && <p>{submitMessage}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionMap;
