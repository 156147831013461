import PrivacyPolicyContent from "components/PrivacyPolicy/PrivacyPolicyContent";
import LayoutOne from "layouts/LayoutOne";
import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
    return (
      <Fragment>
        <MetaTags>
          <title> {t('PrivacyPolicy')} </title>
          <meta name="description" content="" />
        </MetaTags>
        <LayoutOne>
            <PrivacyPolicyContent/>
        </LayoutOne>
      </Fragment>
    );
  };
  
  export default PrivacyPolicy;