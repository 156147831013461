import React from "react";
import MainTitle from "../Common/MainTitle";
import RelatedImg1 from "../../assets/images/Releated1.png";
import RelatedImg2 from "../../assets/images/Releated2.png";
import ServiceQuestion from "./ServiceQuestion";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';

function RelatedService({ relatedServiceData }) {
  const { t } = useTranslation();
  // console.log(relatedServiceData, "relatedServiceData");
  return (
    <>
      {relatedServiceData?.length && (
        <>
          <Fade direction="up" triggerOnce>
            <MainTitle
              text={t('RelatedService')}
              className={"text-center  RelatedMarginTop"}
              position={"center"}
              width={"171px"}
            />
          </Fade>
          <div className="container">
            <div className="row py-4 ">
              {relatedServiceData?.map((value) => {
                return (
                  <>
                    <div className="col-md-6">
                      <Link to={value?.Link}>
                        <img
                          src={value?.imgUrl}
                          className="img-fluid related-service-img"
                          alt="Project1"
                        />
                        </Link>
                      <h4 className="text-center">{value?.serviceName}</h4>
                    </div>
                  </>
                );
              })}
            </div>
            <Fade direction="up" triggerOnce>
              <MainTitle
                text={t('HaveSomeQuestions')}
                className={"text-center"}
                position={"center"}
                width={"81px"}
              />
            </Fade>

            <div className="py-1">
              <ServiceQuestion />
            </div>
          </div>
        </>
      )}
      {/* <MainTitle
        text={"Related Service"}
        className={"text-center  RelatedMarginTop"}
        position={"center"}
        width={"171px"}
      />
      <div className="container">
        <div className="row py-4 ">
          {relatedServiceData?.map((value) => {
            return (
              <>
                <div className="col-md-6">
                  <img
                    src={value?.imgUrl}
                    className="img-fluid object-contain"
                    alt="Project1"
                  />
                </div>
              </>
            );
          })}
        </div>

        <MainTitle
          text={"Have Some Questions?"}
          className={"text-center"}
          position={"center"}
          width={"81px"}
        />

        <div className="py-1">
          <ServiceQuestion />
        </div>
      </div> */}
    </>
  );
}

export default RelatedService;
