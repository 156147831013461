import React from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function SectionAboutUs() {
  const { t } = useTranslation();
  return (
    <>
      <div className="about-class">
          <div className="row m-0">
            <div className="col-md-6 section-about-1 ">
              <div className="home-about-details">
                <Fade direction="up">
                  <h2 className="text-white"> {t('Aboutus')} </h2>
                </Fade>
                <Fade direction="left">
                  <p className="text-justify">
                  {t('AboutUsParagraph')}
                  </p>
                </Fade>
                <Fade direction="left" delay={200}>
                </Fade>
                <Fade direction="up">
                  <div>
                  <Link to='/about' className="explore-btn">{t('ExploreMore')}</Link>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="col-md-6 section-about-2">
              <div className="about-knowledge">
                <Fade direction="up">
                  <h2>{t('OurExpertise')}</h2>
                </Fade>
                <Fade direction="up">
                <h4 className="text-white text-justify">
                {t('OurExpertiseParagraph')}
                </h4>
                </Fade>
                <Fade direction="up">
                  <div>
                    <button className="explore-btn">{t('ExploreMore')}</button>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}

export default SectionAboutUs;
