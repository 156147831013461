import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import { Helmet } from "react-helmet";
// import Slides from '../components/Slides';
// import AboutContent from '../components/AboutContent';
// import ServiceContent from '../components/ServiceContent';
// import Funfact from '../components/Funfact';
// import WorkProcess from '../components/WorkProcess';
// import ChooseUs from '../components/ChooseUs';
// import ProductsList from '../components/ProductsList';
// import PricingList from '../components/PricingList';
// import GalleryContent from '../components/GalleryContent';
// import Team from '../components/Team';
// import Testimonial from '../components/Testimonial';
// import BlogList from '../components/BlogList';
// import ClinetCarousel from '../components/ClinetCarousel';
// import ContactForm from '../components/ContactForm';
import SectionHero from "../components/Home/SectionHero";
// import SectionServiceSlider from "../components/Home/SectionServiceSlider";
import SectionAboutUs from "../components/Home/SectionAboutUs";
import JacksonWhy from "../components/Home/JacksonWhy";
import SubscribeSection from "../components/Home/SubscribeSection";
// import SectionFeatures from "../components/Home/SectionFeatures";
// import SectionWorldWide from "../components/Home/SectionWorldWide";
// import SectionPartner from "../components/Home/SectionPartner";
// import SectionContactUs from "../components/Home/SectionContactUs";
// import SectionGroupAwards from "components/About/SectionGroupAwards";
// import { useDispatch, useSelector } from "react-redux";
// import { GetBranchData } from "redux/service/servicesAction";
import MultiSlider from "components/Home/MultiSlider";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const isState = useSelector((state) => state);
  // console.log(isState, "products");
  // useEffect(() => {
  //   dispatch(GetBranchData());
  // }, []);
  return (
    <Fragment>
      <MetaTags>
        <title>{t('AbhisaAdvisory')}</title>
      </MetaTags>
      <Helmet>
        <meta
          name={t('description')}
          content={t('HomeContent1')}
        />

        <meta
          name="keywords"
          content={t('HomeContent2')}
        />
        <meta
          name="description"
          content={t('HomeContent3')}
        />
        <meta
          name="keywords"
          content={t('HomeContent4')}
        />
        <meta
          name="keywords"
          content={t('HomeContent5')}
        />
        <meta
          name="description"
          content={t('HomeContent6')}
        />
        <meta property="og:url" content="https://www.abhisa.in/" />
        <link rel="canonical" href="https://www.abhisa.in/" />
      </Helmet>
      <LayoutOne>
        <SectionHero />
        <MultiSlider />
        <SectionAboutUs />
        <JacksonWhy />
        <SubscribeSection />
        {/* <SectionFeatures />
        <SectionGroupAwards />
        <SectionWorldWide />
        <SectionPartner />
        <SectionContactUs /> */}

        {/* <Slides /> */}
        {/* <AboutContent /> */}
        {/* <ServiceContent /> */}
        {/* <Funfact /> */}
        {/* <WorkProcess /> */}
        {/* <ChooseUs />  */}
        {/* <ProductsList /> */}
        {/* <PricingList />  */}
        {/* <GalleryContent /> */}
        {/* <Team />  */}
        {/* <Testimonial /> */}
        {/* <BlogList />  */}
        {/* <ClinetCarousel /> */}
      </LayoutOne>
    </Fragment>
  );
};

export default Home;
