import MainTitle from "components/Common/MainTitle";
import ServiceButton from "components/Common/ServiceButton";
import React from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';

function SectionCapableAbh({ CapableData }) {
  const { t } = useTranslation();
  console.log(CapableData, "CapableData");
  return (
    <>
      <div className="">
        <MainTitle
          text={t('WeAreapableToProvide')}
          position={"start"}
          width={"80px"}
        />
        <div className="row">
          {CapableData?.map((data) => (
            <>
              <div className="col-md-6">
                <Fade direction="up" triggerOnce>
                  <ServiceButton title={data?.contentTitle} />
                </Fade>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
}

export default SectionCapableAbh;
