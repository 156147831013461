import React, { useRef, useEffect } from "react";
import "./ScrollToTopButton.css"; // Import your CSS file for styling

const ScrollToTopButton = () => {
  const scrollToTopRef = useRef(null);
  const prevScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 300) {
        scrollToTopRef.current.classList.add("show");
      } else {
        scrollToTopRef.current.classList.remove("show");
      }
      prevScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div ref={scrollToTopRef} className="scroll-to-top-container">
      <button onClick={scrollToTop} className="scroll-to-top-button">
        &#8679;
      </button>
    </div>
  );
};

export default ScrollToTopButton;
