import React from "react"; 

const PrivacyPolicyPage = ({PrivacyPolicyData}) => {
  
    return (
        <>
            <div className="container my-4">
                <div className="row">
                    <div className="privacy-policy-content col-md-12 p-4 rounded">
                        {
                            PrivacyPolicyData?.map((data)=>{
                                return(
                                    <>
                                        <h2 className="text-capitalize">{data?.title}</h2>
                                        <p>{data?.MainsubTitle}</p>
                                        {
                                            data?.content?.map((contentData)=>{
                                                return(
                                                    <>
                                                        <h4>{contentData?.subTitle}</h4>
                                                        
                                                        <p><i class="fa fa-long-arrow-right mr-2"></i>{contentData?.subContent}</p>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
  };
  
export default PrivacyPolicyPage;