import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
// import Gallery from "./pages/Gallery";
// import GalleryTwo from "./pages/GalleryTwo";
import Contact from "./pages/Contact";
import SingleService from "./pages/SingleService";
// import Team from "./pages/Team";
// import Shop from "./pages/Shop";
// import SingleShop from "./pages/SingleShop";
// import BlogPageTwo from "./pages/BlogPageTwo";
// import BlogPageOne from "./pages/BlogPageOne";
// import SingleBlog from "./pages/SingleBlog";
// import SingleBlog1 from "./pages/SingleBlog1";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import NotFound from "./pages/NotFound";
import Project from "./pages/Project";
import ServiceInnerPage from "./pages/ServiceInnerPage";
// import ProfileDetailsPage from "./pages/ProfileDetailsPage";
// import Careers from "pages/Careers";
// import FeaturesDetails from "pages/FeaturesDetailsPage";
// import PolicyDetails from "pages/PolicyDetails";
import SectionEnquiryButton from "components/Home/SectionEnquiryButton";
import ScrollToTopButton from "components/ServiceInnerPage/ScrollToTopButton";
import PrivacyPolicyContent from "components/PrivacyPolicy/PrivacyPolicyContent";
import PrivacyPolicy from "pages/PrivacyPolicy";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop>
          <Switch>
            {/* <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          /> */}
            <Route exact path={"/"} component={Home} />
            {/* <Route
            path={`${process.env.PUBLIC_URL + "/home"}`}
            component={Home}
          /> */}
            <Route path="/home" component={Home} />
            {/* <Route path="/careers" component={Careers} /> */}
            <Route path="/about" component={About} />
            <Route path="/service" component={Services} />
            <Route path="/services/:name" component={ServiceInnerPage} />
            {/* <Route path="/features-details/:name" component={FeaturesDetails} /> */}
            <Route path="/policy-details" component={PrivacyPolicy} />
            {/* <Route path="/profile-details/:id" component={ProfileDetailsPage} /> */}
            {/* <Route path="/gallery" component={Gallery} />
            <Route path="/gallery-two" component={GalleryTwo} /> */}
            <Route path="/contact" component={Contact} />
            {/* <Route path="/single-services" component={SingleService} />
            <Route path="/team" component={Team} />
            <Route path="/shop" component={Shop} />
            <Route path="/single-shop" component={SingleShop} /> */}
            {/* <Route
            path="/blog"
            component={SingleBlog1}
          /> */}
            {/* <Route
            path="/blog-two"
            component={BlogPageTwo}
          /> */}
            {/* <Route
          
            path="/single-blog"
            component={SingleBlog}
          /> */}
            {/* <Route
            path="/single-blog"
            component={SingleBlog1}
          /> */}
            <Route path="/project" component={Project} />
            <Route path="/single-blog" component={SingleService} />
            <Route path="/cart" component={Cart} />
            <Route path="/checkout" component={Checkout} />
            <Route exact component={NotFound} />
          </Switch>
        </ScrollToTop>
      </Router>

      <ScrollToTopButton />
      <SectionEnquiryButton />
    </>
  );
}

export default App;
