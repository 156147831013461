import React from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';

const SectionAboutBottom = () => {
  const { t } = useTranslation();
  const AboutBottomData = [
    {
      title: t('OurExpertise'),
      content: [
        {
          subContent:
            t('OurExpertiseParagraph'),
        },
      ],
      gridSize: "col-md-6",
      fadeDirection: "up",
    },
    {
      title: t('OurValues'),
      content: [
        {
          subTitle: t('Excellence'),
          subContent:
            t('AboutsubContent1'),
        },
        {
          subTitle: t('AboutsubTitle1'),
          subContent:
          t('AboutsubContent2'),
        },
        {
          subTitle: t('AboutsubTitle2'),
          subContent: t('AboutsubContent3'),
        }
      ],
      gridSize: "col-md-6",
      fadeDirection: "up",
    },
    {
      title: t('AboutsubTitle3'),
      content: [
        {
          subContent:
          t('AboutsubContent4'),
        },
        {
          subContent:
          t('AboutsubContent5'),
        },
        {
          subContent:
          t('AboutsubContent6'),
        },
        {
          subContent: t('AboutsubContent7'),
        },
        {
          subContent:
          t('AboutsubContent8'),
        },
        {
          subContent: t('AboutsubContent9'),
        },
      ],
      gridSize: "col-md-12",
      fadeDirection: "up",
    },
  ];
  return (
    <>
      <div className="about-bottom">
        <div className="container my-4">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-xl-12">
              <div className="expertise-content row">
                {AboutBottomData?.map((data) => {
                  return (
                    <>
                      <div
                        className={`text-justify expertise ${data?.gridSize}`}
                      >
                        <Fade direction={data.fadeDirection} triggerOnce>
                          <h4>{data?.title}</h4>
                          <ul>
                            {data?.content?.map((contentData) => {
                              return (
                                <>
                                  <li>
                                    <h6>{contentData?.subTitle}</h6>
                                    <p>{contentData?.subContent}</p>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </Fade>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionAboutBottom;
