import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";  
import LayoutOne from "../layouts/LayoutOne";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <MetaTags>
        <title>{t("AbhisaAdvisory")} | {t('NotFoundContent1')}</title>
        <meta
          name="description"
          content={t('NotFoundContent2')}
        />
      </MetaTags> 
      <LayoutOne> 
        <div className="errorPage" style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/bg_3.jpg"})` }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center"> 
                    <h2>{t('NotFoundContent3')}</h2>
                    <span>{t('NotFoundContent4')}</span>
                    <p>{t('NotFoundContent5')}</p>
                    <Link className="more-link" to={process.env.PUBLIC_URL + "/"}>
                    {t('NotFoundContent6')}
                    </Link> 
              </div> 
            </div>
          </div>
        </div> 

        </LayoutOne>
    </Fragment>
  );
};

export default NotFound;
