import React from "react";

function SectionHeroBanner({ title, subtitle, className, description }) {
  return (
    <>
      <div id="sub-banner">
        <div className="container">
          <h1>{title}</h1>
          <ul className="page-breadcrumbs">
            <li>
              <a href="/" contentEditable="false">
                <i className="fa fa-home"></i>
              </a>
            </li>
            <li>Abhisa</li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SectionHeroBanner;
