import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";
import { useTranslation } from 'react-i18next';

const Cart = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <MetaTags>
        <title>{t('CartTitle')}</title>
        <meta name="description" content="" />
      </MetaTags>
      <LayoutOne>
        <div className="cart-page">
          {/*====================  breadcrumb area ====================*/}

          <Breadcrumb title="Shopping Cart" />

          {/*====================  End of breadcrumb area  ====================*/}

          {/*====================  Cart area ====================*/}
          <div className="shop_cart">
            <div className="container">
              <div className="shop_cart_title">
                <h2>{t('CartHeader')}</h2>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="table-responsive text-center">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="shop_cart_tr">
                          <th className="text-center">{t('CartProduct')}</th>
                          <th className="text-center">{t('CartProductsName')}</th>
                          <th className="text-center">{t('CartPrice')}</th>
                          <th className="text-center">{t('CartQty')}</th>
                          <th className="text-center">{t('CartTotal')}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="prod">
                            <a href="#/">
                              <img
                                src="assets/images/product_1.png"
                                alt="product"
                              />
                            </a>
                          </td>
                          <td className="ptitle">
                            <a href="#/">{t('CartProduct1Title')}</a>
                          </td>
                          <td className="unit">
                            <span>$160.00</span>
                          </td>
                          <td className="qty">
                            <input type="number" placeholder="1" />
                          </td>
                          <td className="unit">
                            <span>$160.00</span>
                          </td>
                          <td>
                            <a href="#/">
                              <i className="fa fa-trash"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td className="prod">
                            <a href="#/">
                              <img
                                src="assets/images/product_2.png"
                                alt="product"
                              />
                            </a>
                          </td>
                          <td className="ptitle">
                            <a href="#/">{t('CartProduct2Title')}</a>
                          </td>
                          <td className="unit">
                            <span>$178.00</span>
                          </td>
                          <td className="qty">
                            <input type="number" placeholder="1" />
                          </td>
                          <td className="unit">
                            <span>$457.00</span>
                          </td>
                          <td>
                            <a href="#/">
                              <i className="fa fa-trash"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="shop_cart_bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-sm-12">
                  <div className="discount-coupon">
                    <h4>{t('CartDiscountCodesTitle')}</h4>
                    <p>{t('CartDiscountCodesInfo')}</p>
                    <form action="#">
                      <input className="coupon" type="text" />
                    </form>
                    <a className="app-coupon" href="#/">
                      {t('CartApplyCoupon')}
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="estimate-ship">
                    <h4>{t('CartEstimateShippingTaxTitle')}</h4>
                    <div className="country">
                      <p className="title-country">{t('CartCountryTitle')}</p>
                      <select
                        name="sort-by-country"
                        className="sort-by-country"
                      >
                        <option select="select" placeholder="bang">
                          {t('CartCountryOption1')}
                        </option>
                        <option placeholder="eng">{t('CartCountryOption2')}</option>
                        <option placeholder="ger">{t('CartCountryOption3')}</option>
                      </select>
                    </div>
                    <div className="state">
                      <p className="title-state">{t('CartStateTitle')}</p>
                      <select name="sort-by-state" className="sort-by-state">
                        <option select="select" placeholder="dhk">
                          {t('CartStateOption1')}
                        </option>
                        <option placeholder="bar">{t('CartStateOption2')}</option>
                        <option placeholder="khl">{t('CartStateOption3')}</option>
                      </select>
                    </div>
                    <div className="postal-code">
                      <p className="p-code">{t('CartPostalCodeTitle')}</p>
                      <form>
                        <input className="post-code" type="text" />
                      </form>
                    </div>
                    <a className="get-quote" href="#/">
                      {t('CartGetQuote')}
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="grand-total-area">
                    <h4>{t('CartTotalsTitle')}</h4>
                    <p className="sub-total">
                      {t('CartSubTotal')}<span className="amt"> $230.00</span>
                    </p>
                    <p className="delivery">
                      {t('CartDelivery')}<span className="amt"> $10.00</span>
                    </p>
                    <p className="discount">
                      {t('CartDiscount')}<span className="amt"> $20.00</span>
                    </p>
                    <p className="grand-total">
                      {t('CartGrandTotal')} <span className="amt">$200.00</span>
                    </p>
                    <a className="pro-checkout" href="#/">
                      {t('CartProceedToCheckout')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*====================  End of Cart  Section    ====================*/}
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default Cart;
