import React from "react";
import NavIcon from '../../assets/images/navIcon.png'
const HeaderBtn = () => {

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector("#offcanvas-mobile-menu");
    offcanvasMobileMenu.classList.add("active");
  };

  return (
    <>

      <div className="header-btn-wrapper">

        <div className="mobile-button-wrapper d-block d-lg-none text-right">
          <button className="mobile-aside-button" onClick={() => triggerMobileMenu()} >
            {/* <i className="icon-glyph-233"></i> */}
            {/* <i class="fa fa-navicon"></i> */}
            <img src={NavIcon} className="img-fluid mt-4" alt='navIcon' />
          </button>
        </div>

      </div>
    </>
  );
};

export default HeaderBtn;
