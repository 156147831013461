import MainTitle from "components/Common/MainTitle";
import React from "react";
import JTILogo from "../../assets/images/JTI.png";
import Strata from "../../assets/images/Strata.png";
import NeuVo from "../../assets/images/Neuvo.png";
import keyEnergy from "../../assets/images/key-Energy.png";
import SeLan from "../../assets/images/SELAN.png";
import OnGc from "../../assets/images/ONGC.png";
import { useTranslation } from 'react-i18next';

function SectionCompletedProjects() {
  const { t } = useTranslation();
  const CompletedProjectContent = [
    {
      imgUrl: JTILogo,
      content: [
        t('CompletedProjectsTitle1'),
      ],
    },
    {
      imgUrl: Strata,
      content: [
        t('CompletedProjectsTitle2'),
        t('CompletedProjectsTitle3'),
        t('CompletedProjectsTitle4'),
      ],
    },
    {
      imgUrl: NeuVo,
      content: [
        t('CompletedProjectsTitle5'),
      ],
    },
    {
      imgUrl: keyEnergy,
      content: [
        t('CompletedProjectsTitle6'),
      ],
    },
    {
      imgUrl: SeLan,
      content: [
        t('CompletedProjectsTitle7'),
        t('CompletedProjectsTitle8'),
      ],
    },
    {
      imgUrl: OnGc,
      content: [
        t('CompletedProjectsTitle9'),
        t('CompletedProjectsTitle10'),
      ],
    },
  ];
  const CompletedProjectList = [
    t('CompletedProjectsTitle11'),
    t('CompletedProjectsTitle12'),
    t('CompletedProjectsTitle13'),
    t('CompletedProjectsTitle14'),
    t('CompletedProjectsTitle15'),
    t('CompletedProjectsTitle16'),
    t('CompletedProjectsTitle17'),
    t('CompletedProjectsTitle18'),
    t('CompletedProjectsTitle19'),
    t('CompletedProjectsTitle20'),
    t('CompletedProjectsTitle21'),
    t('CompletedProjectsTitle22'),
    t('CompletedProjectsTitle23'),
    t('CompletedProjectsTitle24'),
    t('CompletedProjectsTitle25'),
    t('CompletedProjectsTitle26'),
    t('CompletedProjectsTitle27'),
  ];
  return (
    <>
      <div className="container pt-4 ">
        <MainTitle
          text={t('CompletedProjectsMainTitle')}          className={"text-center"}
          position={"center"}
          width={"171px"}
        />

        {CompletedProjectContent?.map((data, index) => (
          <div
            className={`row py-4 ${index % 2 === 0 ? "" : "flex-row-reverse"}`}
            key={index}
          >
            <div className="col-md-8">
              {data?.content?.map((value) => (
                <div className="d-flex">
                  <div className=" NewsIcon">
              <i class="fa fa-circle primary-color" aria-hidden="true" ></i>

                  </div>
                  <li className="projectList ls-none ">{value}</li>
                </div>
              ))}
            </div>
            <div className="col-md-3">
              <img src={data?.imgUrl} alt="img" className="img-fluid" />
            </div>
          </div>
        ))}

        <div className="row">
          {CompletedProjectList?.map((content, index) => (
            <div className="d-flex" key={index}>
              <div className=" NewsIcon">
              <i class="fa fa-circle primary-color" aria-hidden="true" ></i>

</div>
              <li className="projectList text-justify " style={{ listStyle:'none' }}>{content}</li>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SectionCompletedProjects;

