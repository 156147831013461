import React, { Fragment } from "react";
import LayoutOne from "../layouts/LayoutOne";
import customeDuty from "../assets/images/Customs-duty.png";
// import gstImg from "../assets/images/GST.jpg";
// import incomeTax from "../assets/images/income-tax-advisory-services.webp";
import landRevenue from "../assets/images/land-revenue1.jpg";
import gstImg from "../assets/images/AbhisaGSTFinal.png";
import greenLayout from "../assets/images/AbhisaLandAquisitionndGreenFieldManufacturingProjectSetUp.png";
import exportImport from "../assets/images/AbhisaImportAndExportConsultancy.png";
import incomeTaxAdvisoryImg from "../assets/images/AbhisaIncomeTaxAdvisoryServicesOfIndia.png";
import { Helmet } from "react-helmet";

import { useParams } from "react-router-dom";
import SectionServiceBottom from "components/ServiceInnerPage/SectionServiceBottom";
import ServiceLayout from "components/ServiceInnerPage/ServiceLayout";
import SectionCapableAbh from "components/ServiceInnerPage/SectionCapableAbh";
import RelatedService from "components/ServiceInnerPage/RelatedService";
import SectionHeroBanner from "components/Common/SectionHeroBanner";
import { useTranslation } from 'react-i18next';

function ServiceInnerPage() {
  const { t } = useTranslation();
  const ServiceInnerPageData = [
    {
      title: t('Customsduty'),
      slug: "customs-duty",
      metaDescription:
        t('CDmetaDescription'),
      metaKeywords:
        t('CDmetaKeywords'),
      ogDescription:
        t('CDogDescription'),
      ogTitle: t('CDogTitle'),
      CardButtonsData: [
        t('CDTariffClassification'),
        t('CDCustomsValuation'),
        t('CDDutyOptimization'),
        t('CDCustomsCompliance'),
        t('CDTradeAgreements'),
      ],
      imgUrl: customeDuty,
      innerData: {
        mainHeader: t('Customsduty'),
        subHeader:
          t('CDsubHeader'),
        description: [
          t('CDdescription1'),
        ],
      },
      serviceMiddleData: [
        {
          ServiceMiddletitle: t('CDServiceMiddletitle1'),
          content: [
            {
              contentTitle: t('CDcontentTitle1'),
              content1:
                t('CDcontentDescription1'),
            },
            {
              contentTitle: t('CDcontentTitle2'),
              content1:
                t('CDcontentDescription2'),
            },
          ],
        },

        {
          ServiceMiddletitle: t('CDServiceMiddletitle2'),
          content: [
            {
              contentTitle: t('CDcontentTitle3'),
              content1:
                t('CDcontentDescription3'),
            },
            {
              contentTitle: t('CDcontentTitle4'),
              content1:
                t('CDcontentDescription4'),
            },
          ],
        },
      ],
      serviceBottomData: [
        {
          ServiceBottamtitle: t('CDServiceBottamtitle1'),
          content: [
            {
              content1:
                t('CDbottomContent1'),
            },
            {
              content1:
                t('CDbottomContent2'),
            },
            {
              content1:
                t('CDbottomContent3'),
            },
            {
              content1:
                t('CDbottomContent4'),
            },
            {
              content1:
                t('CDbottomContent5'),
            },
          ],
        },
        {
          ServiceBottamtitle:
            t('CDServiceBottamtitle2'),
          content: [
            {
              content1:
                t('CDbottomContentGST1'),
            },
            {
              content1:
                t('CDbottomContentGST2'),
            },
            {
              content1: t('CDbottomContentGST3'),
            },
            {
              content1:
                t('CDbottomContentGST4'),
            },
            {
              content1:
                t('CDbottomContentGST5'),
            },
          ],
        },
      ],
      relatedServiceData: [
        {
          imgUrl: gstImg,
          Link: "/services/gst-advisory-services",
          serviceName: t('GST'),
        },
        {
          imgUrl: incomeTaxAdvisoryImg,
          Link: "/services/income-tax-advisory-services",
          serviceName: t('Incometax'),
        },
      ],
    },
    {
      title: t('GST'),
      slug: "gst-advisory-services",
      metaDescription:
        t('GSTmetaDescription'),
      metaKeywords:
        t('GSTmetaKeywords'),
      ogDescription:
        t('GSTogDescription'),
      ogTitle: t('GSTogTitle'),
      CardButtonsData: [
        t('GSTRegistrationBtn'),
        t('GSTComplianceBtn'),
        t('GSTRFilingBtn'),
        t('GSTAdvisoryBtn'),
        t('GSTAuditBtn'),
      ],
      imgUrl: gstImg,
      innerData: {
        mainHeader: t('GST'),
        subHeader:
          t('GSTsubHeader'),
        description: [
          t('GSTdescription1'),
          t('GSTdescription2'),
        ],
      },
      serviceMiddleData: [
        {
          content: [
            {
              contentTitle: t('GSTRegistrationBtn'),
              content1:
                t('GSTRegistrationcontent1'),
              content2:
                t('GSTRegistrationcontent2'),
            },
            {
              contentTitle: t('GSTCompliancetitle'),
              content1:
                t('GSTCompliancecontent1'),
              content2:
                t('GSTCompliancecontent2'),
              content3:
                t('GSTCompliancecontent3'),
            },
            {
              contentTitle: t('GSTAdvisorytitle'),
              content1:
                t('GSTAdvisorycontent1'),
              content2:
                t('GSTAdvisorycontent2'),
            },
            {
              contentTitle: t('GSTAppealstitle'),
              content1:
                t('GSTAppealscontent1'),
              content2:
                t('GSTAppealscontent2'),
            },
            {
              contentTitle: t('GSTTrainingtitle'),
              content1:
                t('GSTTrainingcontent1'),
              content2:
                t('GSTTrainingcontent2'),
            },
          ],
        },
      ],
      whyChooseUs: [
        {
          title: t('ExpertiseandExperience'),
          content:
            t('GSTwhyChooseUsExpertiseandExperience'),
        },
        {
          title: t('GSTwhyChooseUsClientCentric'),
          content:
            t('ClientCentriccontent1'),
          content1:
            t('ClientCentriccontent2'),
          content2:
            t('ClientCentriccontent3'),
        },
        {
          title: t('TechnologyIntegrationtitle'),
          content:
            t('TechnologyIntegrationcontent'),
        },
        {
          title: t('ComprehensiveSupporttitle'),
          content:
            t('ComprehensiveSupportcontent'),
        },
      ],
      serviceBottomData: [
        {
          ServiceBottamtitle: t('GSTserviceBottomServiceBottamtitle'),
          content: [
            {
              content1:
                t('GSTserviceBottomcontent1'),
            },
            {
              content1:
                t('GSTserviceBottomcontent2'),
            },
            {
              content1: t('GSTserviceBottomcontent3'),
            },
            {
              content1: t('GSTserviceBottomcontent4'),
            },
            {
              content1: t('GSTserviceBottomcontent5'),
            },
          ],
        },
        {
          ServiceBottamtitle: t('ServiceBottamtitle'),
          content: [
            {
              content1: t('ServiceBottamcontent1'),
            },
            {
              content1:
                t('ServiceBottamcontent2'),
            },
            {
              content1: t('ServiceBottamcontent3'),
            },
            
            {
              content1:
                t('ServiceBottamcontent4'),
            },
          ],
        },
      ],
      contactUs: [
        {
          title:
            t('GSTcontactUs'),
        },
      ],
      calltoAction: [
        {
          title:
            t('GSTcalltoAction'),
        },
      ],
      relatedServiceData: [
        {
          imgUrl: incomeTaxAdvisoryImg,
          Link: "/services/income-tax-advisory-services",
          serviceName: t('Incometax'),
        },
        {
          imgUrl: landRevenue,
          Link: "/services/land-revenue-acquisition",
          serviceName: "Land Revenue-Acquis",
        },
      ],
    },
    {
      title: t('Incometax'),
      slug: "income-tax-advisory-services",
      metaDescription:
        t('ITmetaDescription'),
      metaKeywords:
        t('ITmetaKeywords'),
      ogTitle: t('ITogTitle'),
      ogDescription:
        "We offer comprehensive Income Tax Advisory Services including tax planning, return preparation, compliance, audit support, and consulting. Contact us to optimize your tax strategy and ensure compliance with income tax laws.",
      CardButtonsData: [
        t('ITRegistrationBtn'),
        t('ITComplianceBtn'),
        t('ITRFilingBtn'),
        t('ITAdvisoryBtn'),
        t('ITAuditBtn'),
      ],
      imgUrl: incomeTaxAdvisoryImg,
      innerData: {
        mainHeader: t('Incometax'),
        subHeader:
          t('ITsubHeader'),
        description: [
          t('ITdescription1'),
        ],
      },
      serviceMiddleData: [
        {
          content: [
            {
              contentTitle: t('ITcontentTitle1'),
              content1:
                t('ITcontentDesc1'),
              content2:
                t('ITcontentDesc2'),
            },
            {
              contentTitle: t('ITcontentTitle2'),
              content1:
                t('ITcontentDesc4'),
              content2: ('ITcontentDesc4'),
            },
            {
              contentTitle: t('ITcontentTitle3'),
              content1:
                t('ITcontentDesc6'),
              content2:
                t('ITcontentDesc6'),
            },
            {
              contentTitle:t('ITcontentTitle4'),
              content1:
                t('ITcontentDesc7'),
              content2:
                t('ITcontentDesc8'),
            },
            {
              contentTitle: t('ITcontentTitle5'),
              content1:
              t('ITcontentDesc9'),
              content2:
              t('ITcontentDesc10'),
            },
            {
              contentTitle: t('ITcontentTitle6'),
              content1:
              t('ITcontentDesc11'),
              content2:
              t('ITcontentDesc12'),
            },
            // {
            //   contentTitle: "Appeals and Representations:",
            //   content1:
            //     "Representation before Income Tax authorities for assessments, audits, appeals,reply of Show Cause Notices and cases booked by the enforcement agencies like Investigation wing, or Survey by Income Tax authorities.",
            //   content2:
            //     "Assistance in responding to Income Tax Show Cause Notices and handling litigation matters.",
            // },
          ],
        },
      ],
      whyChooseUs: [
        {
          title: t('ExpertiseandExperience'),
          content:
            t('ITwhyChooseUsExpertiseandExperience'),
        },
        {
          title: t('ITwhyChooseUsClientCentric'),
          content:
            t('ITClientCentriccontent1'),
          content1:
            t('ITClientCentriccontent2'),
          content2:
          t('ITClientCentriccontent3'),
        },
        {
          title: t('ITTechnologyIntegrationtitle'),
          content:
            t('ITTechnologyIntegrationcontent'),
        },
        {
          title: t('ITComprehensiveSupporttitle'),
          content:
            t('ITComprehensiveSupportcontent'),
        },
      ],
      serviceBottomData: [
        {
          ServiceBottamtitle:
            t('ITserviceBottomServiceBottamtitle'),
          content: [
            {
              content1:
              t('ITserviceBottomcontent1'),
            },
            {
              content1:
                t('ITserviceBottomcontent2'),
            },
            {
              content1:
              t('ITserviceBottomcontent3'),
            },
            {
              content1:
                t('ITserviceBottomcontent4'),
            },
          ],
        },
        {
          ServiceBottamtitle:
            t('ITServiceBottamtitle'),
          content: [
            {
              content1: t('ITServiceBottamcontent1'),
            },
            {
              content1: t('ITServiceBottamcontent2'),
            },
            {
              content1: t('ITServiceBottamcontent3'),
            },
            {
              content1: t('ITServiceBottamcontent4'),
            },
            {
              content1: t('ITServiceBottamcontent5'),
            },
          ],
        },
      ],
      contactUs: [
        {
          title:
            t('ITcontactUs'),
          // content:
          //   "Contact us today to discuss how Abhisa Advisory LLP can support your  needs.",
        },
      ],
      calltoAction: [
        {
          title:
            t('ITcalltoAction'),
        },
      ],
      relatedServiceData: [
        {
          imgUrl: landRevenue,
          Link: "/services/land-revenue-acquisition",
          serviceName: t('LandRevenueAcquisition'),
        },
        {
          imgUrl: exportImport,
          Link: "/services/export-import-advisory-services",
          serviceName: t('ExportImportadvisory'),
        },
      ],
    },
    {
      title: t('LandRevenueAcquisition'),
      slug: "land-revenue-acquisition",
      ogDescription:
        t('LRAogDescription'),
      metaDescription:
      t('LRAmetaDescription,'),
      ogTitle: t('LRAogTitle'),
      CardButtonsData: [
        t('LRACardButton1'),
        t('LRACardButton2'),
        t('LRACardButton3'),
        t('LRACardButton4'),
        t('LRACardButton5'),
      ],
      imgUrl: landRevenue,
      innerData: {
        mainHeader: t('LRAmainHeader'),
        subHeader:
          t('LRAsubHeader'),
        description: [
          t('LRAdescription1'),
          t('LRAdescription2'),
        ],
      },
      serviceBottomData: [
        {
          ServiceBottamtitle:
          t('LRAServiceBottomTitle1'),
          content: [
            {
              content1:
                t('LRAServiceBottomContent1'),
            },
            {
              content1:
                t('LRAServiceBottomContent2'),
            },
            {
              content1:
                t('LRAServiceBottomContent3'),
            },
            {
              content1:
                t('LRAServiceBottomContent4'),
            },
          ],
        },
        {
          ServiceBottamtitle:
            t('LRAServiceBottomTitle2'),
          content: [
            {
              content1: t('LRAServiceBottomContent5'),
            },
            {
              content1: t('LRAServiceBottomContent6'),
            },
            {
              content1: t('LRAServiceBottomContent7'),
            },
            {
              content1: t('LRAServiceBottomContent8'),
            },
            {
              content1: t('LRAServiceBottomContent9'),
            },
          ],
        },
      ],
      relatedServiceData: [
        {
          imgUrl: greenLayout,
          Link: "/services/green-field-project-set-up",
          serviceName: t('LRArelatedService1Name'),
        },
        {
          imgUrl: exportImport,
          Link: "/services/export-import-advisory-services",
          serviceName: t('LRArelatedService2Name'),
        },
      ],
    },

    {
      title: t('GreenFieldProjectSetUp'),
      metaDescription:
        t('GFPmetaDescription'),
      metaKeywords:
        t('GFPmetaKeywords'),
      ogDescription:
        t('GFPogDescription'),
      ogTitle: t('GFPogTitle'),
      clientBenefits: [
        {
          content1:
            t('GFPclientBenefit1'),
        },
        {
          content2:
            t('GFPclientBenefit2'),
        },
      ],
      CardButtonsData: [
        t('GFPCardButton1'),
        t('GFPCardButton2'),
        t('GFPCardButton3'),
        t('GFPCardButton4'),
        t('GFPCardButton5'),
      ],

      slug: "green-field-project-set-up",
      imgUrl: greenLayout,
      innerData: {
        mainHeader: t('GFPmainHeader'),
        subHeader:
          t('GFPsubHeader'),
        description: [
          t('GFPdescription'),
        ],
      },
      serviceMiddleData: [
        {
          content: [
            {
              contentTitle: t('GFPIndustrialLandAcquisition'),
              content1:
                t('GFPserviceMiddleContent1'),
            },
            {
              contentTitle: t('GFPserviceMiddleContent2'),
              content1:
                t('GFPserviceMiddleContent2'),
            },
            {
              contentTitle: t('GFPLegalPropertyConsultant'),
              content1:
                t('GFPserviceMiddleContent3'),
            },
            {
              contentTitle: t('GFPserviceMiddleData4'),
              content1:
                t('GFPserviceMiddleContent4'),
            },
            {
              contentTitle: t('GFPserviceMiddleData16'),
              content1:
                t('GFPserviceMiddleContent5'),
            },
            {
              contentTitle: t('GFPserviceMiddleData5'),
              content1:
                t('GFPserviceMiddleContent6'),
            },
            {
              contentTitle: t('GFPserviceMiddleData6'),
              content1:
                t('GFPserviceMiddleContent7'),
            },
            {
              contentTitle: t('GFPserviceMiddleData7'),
              content1:
                t('GFPserviceMiddleContent8'),
            },
            {
              contentTitle: t('GFPserviceMiddleData8'),
              content1:
                t('GFPserviceMiddleContent9'),
            },
            {
              contentTitle: t('GFPserviceMiddleData9'),
              content1:
                t('GFPserviceMiddleContent10'),
            },
            {
              contentTitle: t('GFPserviceMiddleData10'),
              content1:
                t('GFPserviceMiddleContent11'),
            },
            {
              contentTitle: t('GFPserviceMiddleData11'),
              content1:
                t('GFPserviceMiddleContent12'),
            },
            {
              contentTitle: t('GFPserviceMiddleData12'),
              content1:
                t('GFPserviceMiddleContent13'),
            },
            {
              contentTitle: t('GFPserviceMiddleData13'),
              content1:
                t('GFPserviceMiddleContent14'),
            },
            {
              contentTitle: t('GFPserviceMiddleData14'),
              content1:
                t('GFPserviceMiddleContent15'),
            },
            {
              contentTitle: t('GFPserviceMiddleData15'),
              content1:
                t('GFPserviceMiddleContent16'),
            },
          ],
        },
      ],
      whyChooseUs: [
        {
          title: t('ExpertiseandExperience'),
          content:
            t('GFPwhyChooseUs1'),
        },
        {
          title: t('GFPwhyChooseUs3'),
          content:
            t('GFPwhyChooseUs2'),
        },
      ],

      serviceBottomData: [
        {
          ServiceBottamtitle: t('GFPserviceBottomTitle1'),
          content: [
            {
              content1:
                t('GFPserviceBottomContent1'),
            },
            {
              content1:
                t('GFPserviceBottomContent2'),
            },
            {
              content1:
                t('GFPserviceBottomContent3'),
            },
            {
              content1:
                t('GFPserviceBottomContent4'),
            },
            {
              content1:
                t('GFPserviceBottomContent5'),
            },
          ],
        },
        {
          ServiceBottamtitle: t('GFPserviceBottomTitle2'),
          content: [
            {
              content1:
                t('GFPserviceBottomContent6'),
            },
            {
              content1:
                t('GFPserviceBottomContent7'),
            },
            {
              content1:
                t('GFPserviceBottomContent8'),
            },
            {
              content1:
                t('GFPserviceBottomContent9'),
            },
            {
              content1:
                t('GFPserviceBottomContent10'),
            },
          ],
        },
        {
          ServiceBottamtitle:t('GFPserviceBottomTitle3'),
          content: [
            {
              content1:
                t('GFPserviceBottomContent11'),
            },
            {
              content1: t('GFPserviceBottomContent12'),
            },
            {
              content1:
                t('GFPserviceBottomContent13'),
            },
            {
              content1:
               t('GFPserviceBottomContent14'),
            },
            {
              content1:
                t('GFPserviceBottomContent15'),
            },
          ],
        },
      ],

      content1:
        t('GFPcontent1'),
      content2:
        t('GFPcontent2'),
      content3:
        t('GFPcontent3'),

      CapableData: [
        {
          title: t('GFPcapableData1'),
        },
        {
          title: t('GFPcapableData2'),
        },
        {
          title: t('GFPcapableData3'),
        },
        {
          title: t('GFPcapableData4'),
        },
      ],

      relatedServiceData: [
        {
          imgUrl: exportImport,
          Link: "/services/export-import-advisory-services",
          serviceName: t('GFPrelatedService1Name'),
        },
        {
          imgUrl: customeDuty,
          Link: "/services/customs-duty",
          serviceName: t('GFPrelatedService2Name'),
        },
      ],

      contactUs: [
        {
          title:
            t('GFPcontactUsTitle'),
          // content:
          //   "Contact us today to discuss how Abhisa Advisory LLP can support your  needs.",
        },
      ],
      calltoAction: [
        {
          title:
            t('GFPcallToActionTitle'),
        },
      ],
    },
    {
      title: t('ExportImportadvisory'),
      metaDescription:
        t('EIAmetaDescription'),
      metaKeywords:
        t('EIAmetaKeywords'),
      ogDescription:
        t('EIAogDescription'),
      ogTitle: t('EIAogTitle'),
      clientBenefits: [
        {
          content1:
          t('EIAclientBenefits1'),
        },
        {
          content2:
          t('EIAclientBenefits2'),
        },
      ],
      CardButtonsData: [
        t('EIACardButtonsData1'),
        t('EIACardButtonsData2'),
        t('EIACardButtonsData3'),
        t('EIACardButtonsData4'),
        t('EIACardButtonsData5'),
      ],

      slug: "export-import-advisory-services",
      imgUrl: exportImport,
      innerData: {
        mainHeader: t('EIAmainHeader'),
        subHeader:
          t('EIAsubHeader'),
        description: [
          t('EIAdescription1'),
        ],
      },
      serviceMiddleData: [
        {
          content: [
            {
              contentTitle: t('EIAserviceMiddleData1Title'),
              content1:
                t('EIAserviceMiddleData1Content1'),
              content2:
                t('EIAserviceMiddleData1Content2'),
              content3:
                t('EIAserviceMiddleData1Content3'),
            },
            {
              contentTitle: t('EIAserviceMiddleData2Title'),
              content1:
                t('EIAserviceMiddleData2Content1'),
              content2:
                t('EIAserviceMiddleData2Content2'),
              content3:
                t('EIAserviceMiddleData2Content3'),
            },
            {
              contentTitle: t('EIAserviceMiddleData3Title'),
              content1:
                t('EIAserviceMiddleData3Content1'),
              content2:
                t('EIAserviceMiddleData3Content2'),
            },
            {
              contentTitle: t('EIAserviceMiddleData4Title'),
              content1:
               t('EIAserviceMiddleData4Content1'),
              content2:
               t('EIAserviceMiddleData4Content2'),
              content3:
               t('EIAserviceMiddleData4Content3'),
            },
            {
              contentTitle: t('EIAserviceMiddleData5Title'),
              content1:
                t('EIAserviceMiddleData5Content1'),
            },
          ],
        },
      ],
      whyChooseUs: [
        {
          title:t('EIAwhyChooseUs1Title'),
          content:
            t('EIAwhyChooseUs1Content'),
        },
        {
          title: t('EIAwhyChooseUs2Title'),
          content:
            t('EIAwhyChooseUs2Content'),
        },
      ],

      serviceBottomData: [
        {
          ServiceBottamtitle: t('EIAserviceBottomData1Title'),
          content: [
            {
              content1:
                t('EIAserviceBottomData1Content1'),
            },
            {
              content1:
                t('EIAserviceBottomData1Content2'),
            },
            {
              content1:
                t('EIAserviceBottomData1Content3'),
            },
            {
              content1:
                t('EIAserviceBottomData1Content4'),
            },
            {
              content1:
               t('EIAserviceBottomData1Content5'),
            },
          ],
        },
        {
          ServiceBottamtitle: t('EIAserviceBottomData2Title'),
          content: [
            {
              content1:
                t('EIAserviceBottomData2Content1'),
            },
            {
              content1:
               t('EIAserviceBottomData2Content2'),
            },
            {
              content1:
                t('EIAserviceBottomData2Content3'),
            },
            {
              content1:
                t('EIAserviceBottomData2Content4'),
            },
            {
              content1:
                t('EIAserviceBottomData2Content5'),
            },
          ],
        },
        {
          ServiceBottamtitle: t('EIAserviceBottomData3Title'),
          content: [
            {
              content1:
                t('EIAserviceBottomData3Content1'),
            },
            {
              content1: t('EIAserviceBottomData3Content2'),
            },
            {
              content1:
               t('EIAserviceBottomData3Content3'),
            },
            {
              content1:
                t('EIAserviceBottomData3Content4'),
            },
            {
              content1:
                t('EIAserviceBottomData3Content5'),
            },
          ],
        },
      ],

      content1:
        t('EIAcontent1'),
      content2:
        t('EIAcontent2'),
      content3:
        t('EIAcontent3'),

      CapableData: [
        {
          title: t('EIAcapableData1Title'),
        },
        {
          title: t('EIAcapableData2Title'),
        },
        {
          title: t('EIAcapableData3Title'),
        },
        {
          title: t('EIAcapableData4Title'),
        },
      ],

      relatedServiceData: [
        {
          imgUrl: customeDuty,
          Link: "/services/customs-duty",
          serviceName: t('EIArelatedServiceData1ServiceName'),
        },
        {
          imgUrl: gstImg,
          Link: "/services/gst-advisory-services",
          serviceName: t('EIArelatedServiceData2ServiceName'),
        },
      ],

      contactUs: [
        {
          title:
            t('EIAcontactUs1Title'),
          content:
            t('EIAcontactUs1Content'),
          content1:
            t('EIAcontactUs1Content1'),
        },
      ],
      calltoAction: [
        {
          title:
            t('EIAcalltoAction1Title'),
        },
      ],
    },
  ];
  const { name } = useParams();

  const filteredData = ServiceInnerPageData.filter(
    (item) => item.slug === name
  );

  const {
    title,
    imgUrl,
    content1,
    innerData,
    serviceBottomData,
    serviceMiddleData,
    whyChooseUs,
    clientBenefits,
    contactUs,
    calltoAction,
    content2,
    content3,
    SectionTitle1,
    SectionTitle1Content,
    SectionTitle2,
    SectionTitle2Content,
    Specification,
    buttonText,
    liText,
    slug,
    capable,
    CapableData,
    CardButtonsData,
    relatedServiceData,
    metaDescription,
    metaKeywords,
    ogTitle,
    ogDescription,
  } = filteredData[0] ? filteredData[0] : [];
  console.log("meta desc:", metaDescription);
  // const descriptionContent = innerData?.description?.join(" ");
  // console.log(descriptionContent, "descriptionContent");

  return (
    <>
      <Fragment>
        {/* <MetaTags>
          <title>Abhisa Advisory | Services</title>
          <meta name="description" content="" />
        </MetaTags> */}

        <Helmet>
          {/* <title>{`Abhisa Advisory LLP - ${title}`}</title> */}
          <title>{ogTitle}</title>
          <meta property="og:title" content={ogTitle ?? ""} />
          <meta name="description" content={metaDescription ?? ""} />
          <meta property="og:description" content={ogDescription ?? ""} />
          <meta property="og:image" content={imgUrl ?? ""} />
          <meta property="og:keywords" content={metaKeywords ?? ""} />
          <meta name="keywords" content={metaKeywords ?? ""} />
          <meta name="robots" content="index, follow" />
          <meta
            property="og:url"
            content={`https://abhisa.in/services/${slug ?? ""}`}
          />
          <link
            rel="canonical"
            href={`https://abhisa.in/services/${slug ?? ""}`}
          />
          <meta property="og:type" content="website" />
          {/* Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@AbhisaAdvisory" />
          <meta charset="UTF-8" />
          <meta name="twitter:title" content={ogTitle ?? ""} />
          <meta name="twitter:description" content={ogDescription ?? ""} />
          <meta name="twitter:image" content={imgUrl ?? ""} />

          {/* Twitter Card End */}
        </Helmet>
        <LayoutOne>
          {filteredData && filteredData[0] ? (
            <>
              <SectionHeroBanner title={title} className={"sectionAboutBg"} />
              <ServiceLayout
                CardButtonsData={CardButtonsData}
                imgUrl={imgUrl}
                innerData={innerData}
                content1={content1}
                content2={content2}
                content3={content3}
                SectionTitle1={SectionTitle1}
                SectionTitle1Content={SectionTitle1Content}
                SectionTitle2={SectionTitle2}
                SectionTitle2Content={SectionTitle2Content}
                buttonText={buttonText}
                Specification={Specification}
                liText={liText}
                capable={capable}
                CapableData={CapableData}
                serviceMiddleData={serviceMiddleData}
              />
              <div className="container">
                <SectionCapableAbh
                  CapableData={
                    serviceMiddleData ? serviceMiddleData[0]?.content : []
                  }
                />
              </div>

              {/* <SectionServiceTop data={innerData} /> */}
              <SectionServiceBottom
                sectiondata={serviceBottomData}
                sectiondata2={serviceMiddleData}
                whyChooseUs={whyChooseUs}
                clientBenefits={clientBenefits}
                contactUs={contactUs}
                calltoAction={calltoAction}
              />
              <RelatedService relatedServiceData={relatedServiceData} />
            </>
          ) : (
            <>
              <h1 className="text-center"> No Data Found </h1>
            </>
          )}
        </LayoutOne>
      </Fragment>
    </>
  );
}

export default ServiceInnerPage;
