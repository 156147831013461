import React from "react";
import onestopicon from "../../assets/images/one-stop-icon.png";
import deliveryicon from "../../assets/images/delivery-icon.png";
import inegrityicon from "../../assets/images/inegrity-icon.png";
import teamicon from "../../assets/images/team-icon.png";
import experianceicon from "../../assets/images/experiance-icon.png";
import costicon from "../../assets/images/cost-icon.png";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';

const JacksonWhy = () => {
  const { t } = useTranslation();
  const jacksonData = [
    {
      title: t('OneStopSource'),
      icon: onestopicon,
    },
    {
      title: t('TimelyDeliveries'),
      icon: deliveryicon,
    },
    {
      title: t('Integrity'),
      icon: inegrityicon,
    },
 
    {
      title: t('DedicatedTeam'),
      icon: teamicon,
    },
    {
      title: t('StellarExperience'),
      icon: experianceicon,
    },
    {
      title: t('CostEffective'),
      icon: costicon,
    },

  ];

  return (
    <>
      <div className="container my-4">
        <Fade direction="left" triggerOnce>
          <h2 className="ml-4 why-Abhisa-Advisory"> {t('WhyAbhisaAdvisory')} </h2>
        </Fade>
        <div className="row col-12">
          {jacksonData.map((data) => (
            <div className="col-md-4">
              <Fade direction="up" triggerOnce>
                <div className="text-center feature-class mx-3 my-3 p-5">
                  <div className="mb-4">
                    <img
                      src={data.icon}
                      className="img-fluid filter-color-blue"
                    />
                  </div>
                  <div className="feature-title">
                    <span>{data.title}</span>
                  </div>
                </div>
              </Fade>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default JacksonWhy;
