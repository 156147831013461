import React from "react";

function MainTitle({ text, className, position, width, fontSize }) {
  return (
    <>
      <h1
        className={`${className} MainTitle `}
        style={{ fontSize: `${fontSize}px` }}
      >
        {" "}
        {text}{" "}
      </h1>
      {position && (
        <div className={`d-flex justify-content-${position}`}>
          <p className="border-title" style={{ width: `${width}` }}></p>
        </div>
      )}
    </>
  );
}

export default MainTitle;
