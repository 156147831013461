import React from 'react'
import InputLabel from "../Common/InputLable";
import { useTranslation } from 'react-i18next';

const ServiceQuestion = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="container pt-4 pb-4">
        <div className="row">
          <div className="col-md-12 p-0">
            <div>
              <form>
                <div className='d-flex'>
                  <div className='row'>

                    <div className='col-md-6 '>
                      <InputLabel text={t('Name')} />
                      <input
                        type="text"
                        placeholder={t('EnterYourFullName')}
                        className="custom-input-element-service mb-2"
                      />
                    </div>
                    <div className='col-md-6'>
                      <InputLabel text={t('MobileNumber')} />
                      <input
                        type="text"
                        placeholder={t('EnterYourMobileNumber')}
                        className="custom-input-element-service  mb-2"
                      />
                    </div>
                  </div>

                </div>



                <InputLabel text={t('Comment')} />
                <textarea
                  type="text"
                  placeholder={t('Messege3')}
                  rows="4"
                  className=" mb-2 service-input-textarea"
                />
                <div className="d-flex justify-content-center">
                  <button className=" btn   Contact-us-submit-btn-service">
                    {t('JobApplysubmitButton')}
                  </button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ServiceQuestion
